const YEAR_EN = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
const SEVER_URL = 'https://api.qingcigame.com';
var obj = {
	index: function() {
		this.loading();
		this.bind();
		this.bindScrollEvent();
		this.starParallax();
		this.getGames();
	},
	aboutUs: function() {
		this.bind();
		this.bindScrollEvent();
		this.getGames();
	},
	contactUs: function() {
		this.bind();
		this.bindScrollEvent();
		this.initValidation();
		this.onSubmitLeaveMessage();
		this.getGames();
	},
	news: function() {
		this.getArticles();
		this.bind();
		this.bindScrollEvent();
		this.getGames();
	},
	newDetail: function() {
		var id = location.search.replace('?id=', '');
		if (!id) {
			layer.msg("ページエラー。");
		} else {
			this.getArticleDetail(id);
		}
		this.bind();
		this.bindScrollEvent();
		this.getGames();
	},
	game: function() {
		this.bind();
		this.bindScrollEvent();
		this.getGames();
	},
	gameDetail: function() {
		var id = location.search.replace('?id=', '');
		if (!id) {
			layer.msg("ページエラー。");
		} else {
			this.getGameDetail(id);
		}
		this.bind();
		this.bindScrollEvent();
		this.getGames();
	},
	agreement: function() {
		this.bind();
		this.bindScrollEvent();
		this.getGames();
	},
	loading: function() {
		$('.index').removeClass('loading');

	    var com_swiper_head = new Swiper('.swiper-container',{
	        resistanceRatio:0,
	        on:{
	            init: function(){
	                swiperAnimateCache(this);
	                swiperAnimate(this); 
	            }, 
	            slideChangeTransitionEnd: function(){ 
	                swiperAnimate(this);
	                $('.banner1_btn ul li').eq(this.realIndex).addClass('active').siblings().removeClass('active');
	            }
	        },
	        autoplay: {
	            stopOnLastSlide: false,
	        	disableOnInteraction: true,
	        },
			effect: 'fade'
	    });

	    $('.banner1_btn ul li').on('mouseenter',function(){
	        $('.banner1_btn ul li').eq($(this).index()).addClass('active').siblings().removeClass('active');
	        com_swiper_head.slideTo($(this).index());
	    });
	},
	infinite: function() {
		if(navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)){
			var htmlWidth = $('html').width();
			if (htmlWidth >= 750) {
				$("html").css({
					"font-size" : "40px"
				});
			} else {
				$("html").css({
					"font-size" :  40 / 750 * htmlWidth + "px"
				});
			}
		}
	},
	bind: function() {
		$(".join_us").on('click', function() {
			layer.msg("COMING SOON");
		});
		$(".expect").on('click', function() {
			layer.msg("お楽しみに。");
		});

		var change = true;
		$('.navBox').on('click','.nav_more',function () {
			var _nav = $('.header-position .nav');
			if (change == true) {
				$('.nav_more').addClass('change');
				_nav.fadeIn();
				_nav.animate({'top': '70px'});
				change = false;
			} else {
				$('.nav_more').removeClass('change');
				_nav.animate({'top': '-100vh'});
				_nav.fadeOut();
				change = true;
			}
		});


		// if (!navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {

		// }
	},
	starParallax: function() {
		if(!navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)){
			$('.game-img').parallax("50%", 0.1, 'x', true);
			$('.map-box').parallax("50%", 0.7, 'x', true);
			$('.location').parallax("30%", 0.7, 'x', true);
			$('.google-map').parallax("50%", -0.15, 'x', false);
		} else {
			$(".google-map").remove();
		}
	},
	bindScrollEvent: function(){
	    $(window).scroll( function() {	        
	        var windowWidth = $(window).width();
			if(windowWidth < 1680){
		        scrollHeight(700);
			} else {
				scrollHeight(900);
			}
	    });

	    function scrollHeight(number) {
	    	var winScrollHeight = $(window).scrollTop();
	        var _this = $('.header-position');
	    	if(winScrollHeight >= number) {
	        	_this.addClass('header-ani');
	        	return
	        }
        	_this.removeClass('header-ani');
	    }
	},
	// 关于我们  提交留言
	onSubmitLeaveMessage: function() { 
		var _this = this;
		$("#submit").on('click', function() {
			var result = _this.onValidation();
			if (result) {
				layer.msg('入力内容に問題があります。確認して再度お試しください。');
				return false;
			}
			var formData = $("#validation-form").serializeArray();
			var data = {};
			formData.forEach((item) => data[item.name] = item.value);
			_this.leaveMessageAjax(data);
		});
	},
	leaveMessageAjax: function(data) {
		this.onAjax({
			url: `${SEVER_URL}/sea/contact`,
	        type: 'POST',
			data: data,
			success: function(json) {
				layer.open({
					title: '操作のヒント',
					content: 'コミット成功',
					btn: ['确认'],
					yes: function(){
						location.reload();
					}
				});
			}
		});
	},
	initValidation: function() {  // 初始化验证
		var _this = this;
		$("#validation-form").find('input[aria-required="true"], input[aria-email="true"], textarea[aria-required="true"]').on('blur', function() {
			var queue = [];
			var that = this;
			if ($(this).attr('aria-required') === 'true') queue.push(_this.verifyRequired);
			if ($(this).attr('aria-email') === 'true') queue.push(_this.verifyEMail);
			queue.reduce((result, fn) => result ? fn(that) : result, true);
		});
	},
	verifyRequired: function(jqDom) {
		if (!$(jqDom).val()) {
			$(jqDom).parent().find('.error-message').html($(jqDom).attr('aria-required-message'));
			$(jqDom).attr('aria-result', false);
			return false;
		} else {
			$(jqDom).parent().find('.error-message').html('');
			$(jqDom).attr('aria-result', '');
			return true;
		}
	},
	verifyEMail: function(jqDom) {
		var val = $(jqDom).val();
		var reg=/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;
		if (!reg.test(val)) {
			$(jqDom).parent().find('.error-message').html($(jqDom).attr('aria-email-message'));
			$(jqDom).attr('aria-result', false);
			return false;
		} else {
			$(jqDom).parent().find('.error-message').html('');
			$(jqDom).attr('aria-result', '');
			return true;
		}
	},
	onValidation: function() {
		$("#validation-form").find('input[aria-required="true"], textarea[aria-required="true"]').blur();
		return $("#validation-form").find('input[aria-result="false"]').length;
	},
	// 获取文章列表
	getArticles: function() {
		var _this = this;
		this.onAjax({
			url: `${SEVER_URL}/sea/article?limit=0`,
	        type: 'GET',
			success: function(json) {
				var data = json.data.list.data || {};
				var html = '';
				for (let attr in data) {
					html += `<dt class="news-year">${attr}</dt>`;
					html += _this.renderArticlesHTML(data[attr]);
				}
				$("#new-list").html(html);
			},
		});
	},
	renderArticlesHTML: function(data) {
		var html = '';
		for (var index = 0; index < data.length; index++) {
			var article = data[index];
			if (index % 2 === 1) {
				html += '<dd class="white-space"></dd>';
			}
			html = html + this.setArticle(article);
		}
		return html;
	},
	setArticle: function(article) {
		var createDate = new Date(article.created_at);
		var html = `<dd class="new-item"><ul><li>${createDate.getFullYear()}</li><li>${createDate.getDate()}</li><li>${
			YEAR_EN[createDate.getMonth()]}</li></ul><a href="new_detail.html?id=${article.id}"><h4>${
			article.article_title}</h4><p>${article.article_excerpt}</p></a></dd>`;

		return html;
	},
	// 获取单个文章
	getArticleDetail: function(id) {
		var _this = this;
		this.onAjax({
			url: `${SEVER_URL}/sea/article/${id}`,
	        type: 'GET',
			success: function(json) {
				var data = json.data.list || {};
				_this.renderArticleDetailHTML(data);
			}
		});
	},
	renderArticleDetailHTML: function(article) {
		var createDate = new Date(article.created_at);
		var year = createDate.getFullYear(), date = createDate.getDate(), month = createDate.getMonth() + 1;
		document.title = article.article_title+' | 株式会社QCPlay';
		document.querySelector('meta[name="description"]').setAttribute("content",article.article_title);
		$(".article-title").html(article.article_title);
		$(".create-date").html(`${year}.${month < 10 ? '0' + month : month }.${date}`);
		$(".new-content").html(article.article_content);
	},
	// 获取游戏列表
	getGames: function() {
		var _this = this;
		this.onAjax({
			url: `${SEVER_URL}/sea/game?limit=0`,
			type: 'GET',
			success: function(json) {
				var data = json.data.list.data || [];
				_this.renderGamesHTML(data);				
			}
		});
	},
	renderGamesHTML: function(data) {
		var html = '';
		var html2 = '';
		for (let idx in data) {
			var game = data[idx];
			var isMiddle = idx % 3 === 1;
			html += `<li ${isMiddle ? 'class="middle"' : ''}><a href="game_detail.html?id=${game.id
				}"><div class="img-box"><img src="${game.icon}" alt=""><div class="mask"></div></div><div class="product-info"><div><h3>${
				game.name}</h3><p>${game.description}</p></div><div class="product-more"><span></span></div></div></a></li>`;
		}
		for (let idx in data) {
			var game = data[idx];
			var isMiddle = idx % 3 === 1;
			html2 += `<li><a href="game_detail.html?id=${game.id}">${game.name}</a></li>`;
		}
		$('.footer .games-list').html(html2);
		var li = document.createElement("li");
			li.innerHTML="<h4>Games</h4>";
		$('.footer .games-list')[0].prepend(li);
		$("#game-list").html(html);
	},
	// 获取单个游戏信息
	getGameDetail: function(id) {
		var _this = this;
		this.onAjax({
			url: `${SEVER_URL}/sea/game/${id}`,
			type: 'GET',
			success: function(json) {
				var data = json.data.list || {};
				_this.renderGameDetailHTML(data);
			},
		});
	},
	renderGameDetailHTML: function(data) {
		document.title = data.name+' | 株式会社QCPlay';
		document.querySelector('meta[name="description"]').setAttribute("content",data.description);
		$(".new-detail-title").html(data.name);
		$(".new-detail-date").html(data.description);
		$(".game-detail-content").html(data.intro);
	},
	// 简易封装常用ajax
	onAjax: function(options) {
		const { url, type, success, error, data } = options;
		$.ajax({
	        url: url,
	        type: type,
			data: data,
			success: function(json) {
				if (json.code !== 200) {
					var message = json.message || "サーバーエラー。";
					layer.msg(message);
					return false;
				}
				success && success(json);
			},
			error: function(err) {
				layer.msg("サーバーエラー。");
				error && error(err);
			}
	    });
	},
};